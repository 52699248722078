import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

/**
 * Import Packages
 */
import { collection, getDocs, addDoc, doc } from "firebase/firestore";

/**
 * Import Hooks
 */
import { useFirestore } from "./FirestoreProvider";

/**
 * Import Reducers
 */
import Loader from "../components/Loader/Loader";

/**
 * Import bad words
 */
import { badWords } from "../data/badWords";

/**
 * Create Context for Provider
 */
export const RoomsContext = createContext();

/**
 * Hook for Rooms Context
 *
 * @returns Context
 */
export function useRooms() {
  return useContext(RoomsContext);
}

/**
 *
 * @param {Object} props
 * @returns
 */
export const RoomsProvider = ({ children }) => {
  /**
   * Get our database
   */
  const { db } = useFirestore();

  const [rooms, setRooms] = useState([]);

  const [isCreatingRoom, setIsCreatingRoom] = useState(false);

  /**
   * Get rooms collection from db
   */
  const getRooms = async () => {
    const rooms = await getDocs(collection(db, "rooms"));

    return rooms.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
  };

  /**
   *
   */
  const refreshRooms = useCallback(async () => {
    const newRooms = await getRooms();

    setRooms(newRooms);

    return newRooms;
  }, [db]);

  /**
   *
   */
  const getRoomByAccessCode = useCallback(
    (accessCode) => {
      return rooms.find((room) => room.accessCode === accessCode) || {};
    },
    [rooms]
  );

  /**
   * Create room
   */
  const createRoom = async () => {
    setIsCreatingRoom(true);

    const accessCode = generateAccessCode();

    // Create a new room with default data
    const newRoom = await addDoc(collection(db, "rooms"), {
      startTime: null,
      endTime: null,
      cohort: "IW",
      accessCode,
    });

    await refreshRooms();

    setIsCreatingRoom(false);

    // Return the data from the room
    return { id: newRoom.id, accessCode };
  };

  /**
   * Generate a random access code
   */
  const generateAccessCode = () => {
    const accessCode = Math.random().toString(36).substring(2, 6).toUpperCase();

    // If the access code already exists, generate a new one
    if (
      rooms.find((room) => room.accessCode === accessCode) ||
      badWords.includes(accessCode)
    ) {
      return generateAccessCode();
    }

    return accessCode;
  };

  useEffect(() => {
    (async () => {
      const rooms = await getRooms();

      setRooms(rooms);
    })();
  }, []);

  if (!rooms.length) {
    return <Loader message="Fetching rooms…" />;
  }

  /**
   *
   */
  return (
    <RoomsContext.Provider
      value={{
        rooms,
        getRooms,
        getRoomByAccessCode,
        createRoom,
        isCreatingRoom,
      }}
    >
      {children}
    </RoomsContext.Provider>
  );
};
