import useSound from "use-sound";
import Countdown from "react-countdown-simple";

import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import { useGame } from "../../contexts/GameProvider";
import { useRoom } from "../../contexts/RoomProvider";

import clickDownSfx from "../../sfx/bloop-1.mp3";
import clickOnSfx from "../../sfx/bloop-2.mp3";
import Leaderboard from "../Leaderboard/Leaderboard";

import "./Admin.css";
import FlashMessage from "../FlashMessage/FlashMessage";

export default function Admin({ phase }) {
  const { room, clearResponses, clearUsers } = useRoom();
  const { setStartTime, clearStartTime } = useGame();
  const { addFlashMessage } = useFlashMessage();

  const [clickDown] = useSound(clickDownSfx, { volume: 0.75 });
  const [clickOn] = useSound(clickOnSfx, { volume: 0.75 });

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div>
      {minutes}:{String(seconds).padStart(2, "0")}
    </div>
  );

  return (
    <div className="page admin">
      <div className="page-content admin-content">
        <h1 className="page-title admin-title">Mission Control</h1>
        <h2 className="page-subtitle admin-subtitle">
          Your access code is {room.data().accessCode}
        </h2>

        <div className="admin-controls">
          <div className="admin-controls-panel">
            <Leaderboard />
          </div>

          <div className="admin-controls-panel panel">
            {(phase === "loading" ||
              phase === "waiting" ||
              phase === "finish") && (
              <>
                <p>When you’re ready, press Start to begin the first phase.</p>
                <p>
                  Participants will have <strong>5 minutes</strong> to enter
                  their name and answer the questions.
                </p>

                <div className="game-controls">
                  <button
                    className="btn btn-primary btn-small"
                    onMouseDown={clickDown}
                    onMouseUp={() => {
                      clickOn();
                      setStartTime();
                      addFlashMessage("The game has started!");
                    }}
                  >
                    Start
                  </button>
                </div>
              </>
            )}
            {phase === "join" && (
              <>
                <p>Players joining</p>
                <h1>
                  <Countdown
                    targetDate={room.data().startTime}
                    renderer={renderer}
                  />
                </h1>
                <div className="game-controls">
                  <button
                    className="btn btn-primary btn-small"
                    onMouseDown={clickDown}
                    onMouseUp={() => {
                      clickOn();
                      clearStartTime();
                      addFlashMessage("The game been reset!");
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-primary btn-small"
                    onMouseDown={clickDown}
                    onMouseUp={() => {
                      clickOn();
                      setStartTime(0, 720000);
                      addFlashMessage("Starting the game early!");
                    }}
                  >
                    Start now
                  </button>
                </div>
              </>
            )}
            {phase === "play" && (
              <>
                <p>Game in progress</p>
                <h1>
                  <Countdown
                    targetDate={room.data().endTime}
                    renderer={renderer}
                  />
                </h1>
                <div className="game-controls">
                  <button
                    className="btn btn-primary btn-small"
                    onMouseDown={clickDown}
                    onMouseUp={() => {
                      clickOn();
                      clearStartTime();
                      addFlashMessage("The game been reset!");
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-primary btn-small"
                    onMouseDown={clickDown}
                    onMouseUp={() => {
                      clickOn();
                      setStartTime(0, 0);
                      addFlashMessage("The game has ended!");
                    }}
                  >
                    End now
                  </button>
                </div>
              </>
            )}

            {room && (
              <>
                <button
                  className="link-btn"
                  onMouseDown={clickDown}
                  onMouseUp={() => {
                    clickOn();
                    clearResponses();
                    addFlashMessage("Responses cleared");
                  }}
                >
                  Clear Responses
                </button>
                <button
                  className="link-btn"
                  onMouseDown={clickDown}
                  onMouseUp={() => {
                    clickOn();
                    clearUsers();
                    addFlashMessage("Users cleared");
                  }}
                >
                  Clear Users
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <FlashMessage />

      <div className="page-footer admin-footer">
        &copy; {new Date().getFullYear()} Interactive Workshops
      </div>
    </div>
  );
}
