export const questions = [
  "What is a thing everyone’s done, except you?",
  "In your opinion, what is the best film of all time?",
  "What’s the best thing you can buy for under £20?",
  "What’s the last book you read?",
  "What’s your most used emoji?",
  "Which actor would play you in a movie of your life?",
  "Which artist or band is your guilty pleasure?",
  "What’s the most expensive thing you’ve broken?",
  "If you had to impress someone, what food would you make?",
  "What’s your secret skill?",
  "Which subject was your least favourite at school?",
  "What is the best ice cream flavour?",
  "What is your karaoke song?",
  "Who is the most famous person you’ve met?",
];
