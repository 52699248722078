import Countdown from "react-countdown-simple";

import "./Scoreboard.css";

export default function Scoreboard({ score, rank, endTime }) {
  const renderer = ({ days, hours, minutes, seconds }) => (
    <div>
      {minutes}:{String(seconds).padStart(2, "0")}
    </div>
  );

  return (
    <div className="scoreboard">
      <div className="scoreboard-score">
        <div className="scoreboard-score-label">Score</div>
        <div className="scoreboard-score-value">{score}</div>
      </div>
      <div className="scoreboard-rank">
        <div className="scoreboard-rank-label">Rank</div>
        <div className="scoreboard-rank-value">{rank}</div>
      </div>
      <div className="scoreboard-timer">
        <div className="scoreboard-timer-label">Time Remaining</div>
        <div className="scoreboard-timer-value">
          <Countdown targetDate={endTime} renderer={renderer} />
        </div>
      </div>
    </div>
  );
}
