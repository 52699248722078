import React, { createContext, useContext } from "react";

/**
 * Import Packages
 */
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

/**
 * Import Hooks
 */
import { useFirestore } from "./FirestoreProvider";
import { useRoom } from "./RoomProvider";

/**
 * Import Reducers
 */
import Loader from "../components/Loader/Loader";

/**
 * Create Context for Provider
 */
export const UserContext = createContext();

/**
 * Hook for Rooms Context
 *
 * @returns Context
 */
export function useUser() {
  return useContext(UserContext);
}

/**
 *
 * @param {Object} props
 * @returns
 */
export const UserProvider = ({ children }) => {
  /**
   * Get our database
   */
  const { db, auth } = useFirestore();

  const { room } = useRoom();

  console.log();

  const [user, loading, error] = useDocument(
    doc(db, "rooms", room.id, "users", auth.currentUser.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (loading) {
    return <Loader message="Fetching user…" />;
  }

  /**
   *
   */
  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
