import Countdown from "react-countdown-simple";

import { useRoom } from "../../contexts/RoomProvider";
import Leaderboard from "../Leaderboard/Leaderboard";

import "./WaitingRoom.css";

export default function WaitingRoom() {
  const { room } = useRoom();

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div>
      Game starts in {minutes}:{String(seconds).padStart(2, "0")}
    </div>
  );

  return (
    <div className="page waiting-room">
      <div className="page-content waiting-room-content">
        <h1 className="page-title waiting-room-title">Waiting Room</h1>

        {room.data().startTime && room.data().startTime > Date.now() ? (
          <div className="waiting-room-timer">
            <h2>
              <Countdown
                targetDate={room.data().startTime}
                renderer={renderer}
              />
            </h2>
          </div>
        ) : (
          <Leaderboard />
        )}
      </div>
      <div className="page-footer waiting-room-footer">
        &copy; {new Date().getFullYear()} Interactive Workshops
      </div>
    </div>
  );
}
