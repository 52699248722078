export const animals = [
  "aardvark",
  "aardwolf",
  "albatross",
  "alligator",
  "alpaca",
  "amphibian",
  "anaconda",
  "angelfish",
  "anglerfish",
  "ant",
  "anteater",
  "antelope",
  "antlion",
  "aphid",
  "armadillo",
  "asp",
  "badger",
  "bandicoot",
  "barnacle",
  "barracuda",
  "basilisk",
  "bass",
  "bat",
  "bear",
  "beaver",
  "bedbug",
  "bee",
  "beetle",
  "bird",
  "bison",
  "blackbird",
  "boa",
  "boar",
  "bobcat",
  "bobolink",
  "bug",
  "butterfly",
  "buzzard",
  "camel",
  "canid",
  "capybara",
  "cardinal",
  "caribou",
  "carp",
  "cat",
  "caterpillar",
  "catfish",
  "catshark",
  "centipede",
  "cephalopod",
  "chameleon",
  "cheetah",
  "chickadee",
  "chicken",
  "chimpanzee",
  "chinchilla",
  "chipmunk",
  "cicada",
  "clam",
  "clownfish",
  "cobra",
  "cod",
  "condor",
  "constrictor",
  "coral",
  "cougar",
  "cow",
  "coyote",
  "crab",
  "crane",
  "cranefly",
  "crawdad",
  "crayfish",
  "cricket",
  "crocodile",
  "crow",
  "cuckoo",
  "damselfly",
  "deer",
  "dingo",
  "dinosaur",
  "dog",
  "dolphin",
  "donkey",
  "dove",
  "dragon",
  "dragonfly",
  "duck",
  "eagle",
  "earthworm",
  "earwig",
  "echidna",
  "eel",
  "egret",
  "elephant",
  "elk",
  "emu",
  "ermine",
  "falcon",
  "ferret",
  "finch",
  "firefly",
  "fish",
  "flamingo",
  "flea",
  "fly",
  "flyingfish",
  "fowl",
  "fox",
  "frog",
  "galliform",
  "gamefowl",
  "gazelle",
  "gecko",
  "geese",
  "gerbil",
  "giraffe",
  "goat",
  "goldfish",
  "gopher",
  "grasshopper",
  "grouse",
  "guan",
  "guanaco",
  "guineafowl",
  "gull",
  "guppy",
  "haddock",
  "halibut",
  "hamster",
  "hare",
  "harrier",
  "hawk",
  "hedgehog",
  "heron",
  "herring",
  "hippopotamus",
  "hookworm",
  "hornet",
  "horse",
  "hoverfly",
  "hummingbird",
  "hyena",
  "iguana",
  "impala",
  "jackal",
  "jaguar",
  "jay",
  "jellyfish",
  "junglefowl",
  "kangaroo",
  "kingfisher",
  "kite",
  "kiwi",
  "koala",
  "koi",
  "krill",
  "ladybug",
  "lamprey",
  "landfowl",
  "lark",
  "leech",
  "lemming",
  "lemur",
  "leopard",
  "limpet",
  "lion",
  "lizard",
  "llama",
  "lobster",
  "locust",
  "loon",
  "louse",
  "lungfish",
  "lynx",
  "macaw",
  "mackerel",
  "magpie",
  "manatee",
  "mandrill",
  "marlin",
  "marmoset",
  "marmot",
  "marsupial",
  "marten",
  "mastodon",
  "meadowlark",
  "meerkat",
  "mink",
  "minnow",
  "mite",
  "mockingbird",
  "mole",
  "mollusk",
  "mongoose",
  "moose",
  "mosquito",
  "moth",
  "mouse",
  "mule",
  "narwhal",
  "newt",
  "nightingale",
  "ocelot",
  "octopus",
  "opossum",
  "orca",
  "ostrich",
  "otter",
  "owl",
  "ox",
  "panda",
  "panther",
  "parakeet",
  "parrot",
  "parrotfish",
  "partridge",
  "peacock",
  "peafowl",
  "pelican",
  "penguin",
  "perch",
  "pheasant",
  "pigeon",
  "pike",
  "pinniped",
  "piranha",
  "planarian",
  "platypus",
  "pony",
  "porcupine",
  "porpoise",
  "prawn",
  "puffin",
  "puma",
  "python",
  "quail",
  "quelea",
  "quokka",
  "rabbit",
  "raccoon",
  "rat",
  "rattlesnake",
  "raven",
  "reindeer",
  "reptile",
  "rhinoceros",
  "roadrunner",
  "rodent",
  "rook",
  "rooster",
  "roundworm",
  "sailfish",
  "salamander",
  "salmon",
  "sawfish",
  "scallop",
  "scorpion",
  "seahorse",
  "shark",
  "sheep",
  "shrew",
  "shrimp",
  "silkworm",
  "silverfish",
  "skink",
  "skunk",
  "sloth",
  "snail",
  "snake",
  "snipe",
  "sole",
  "sparrow",
  "spider",
  "spoonbill",
  "squid",
  "squirrel",
  "starfish",
  "stingray",
  "stoat",
  "stork",
  "sturgeon",
  "swallow",
  "swan",
  "swift",
  "swordfish",
  "swordtail",
  "tahr",
  "takin",
  "tapir",
  "tarantula",
  "tarsier",
  "termite",
  "tern",
  "thrush",
  "tick",
  "tiger",
  "tiglon",
  "toad",
  "tortoise",
  "toucan",
  "trout",
  "tuna",
  "turkey",
  "turtle",
  "tyrannosaurus",
  "urial",
  "vicuna",
  "viper",
  "vole",
  "vulture",
  "wallaby",
  "walrus",
  "warbler",
  "wasp",
  "weasel",
  "whale",
  "whippet",
  "whitefish",
  "wildcat",
  "wildebeest",
  "wildfowl",
  "wolf",
  "wolverine",
  "wombat",
  "woodpecker",
  "worm",
  "wren",
  "yak",
  "zebra",
];

export const randomAnimal = () => {
  return animals[Math.floor(Math.random() * animals.length)];
};
