export default function Check(props) {
  const style = {
    fill: "#000",
    ...props.style,
  };

  return (
    <svg
      width={props.size ? props.size : "1em"}
      height={props.size ? props.size : "1em"}
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className="check"
    >
      <path
        d="m20.707 42.629 17.344 17.344 41.195-41.195c2.5352-2.5352 6.6719-2.5352 9.207 0l3.9102 3.9102c2.5352 2.5352 2.5352 6.6719 0 9.207l-49.328 49.328c-2.5234 2.5234-6.625 2.5391-9.168 0.039063l-26.188-25.75c-1.3125-1.2852-1.9648-2.875-1.9453-4.7109 0.019531-1.8359 0.70703-3.4102 2.043-4.668l3.8594-3.6328c2.5625-2.4102 6.582-2.3516 9.0703 0.13672z"
        style={style}
      />
    </svg>
  );
}
