import React, { createContext, useContext, useState, useEffect } from "react";

/**
 * Import Packages
 */
import {
  collection,
  addDoc,
  getDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";

/**
 * Import Hooks
 */
import { useFirestore } from "./FirestoreProvider";
import { useRoom } from "./RoomProvider";

import { questions } from "../data/questions";

/**
 * Create Context for Provider
 */
export const GameContext = createContext();

/**
 * Hook for Rooms Context
 *
 * @returns Context
 */
export function useGame() {
  return useContext(GameContext);
}

/**
 *
 * @param {Object} props
 * @returns
 */
export const GameProvider = ({ children }) => {
  /**
   * Get our database
   */
  const { db } = useFirestore();

  /**
   * Get room data (we need this to look in the right place for our groups)
   */
  const { room } = useRoom();

  const [gameState, setGameState] = useState({
    name: "",
    questions,
  });

  const [time, setTime] = useState(Date.now());

  /**
   * Refresh time every second
   */
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * Get questions from room
   */
  useEffect(() => {
    if (room.data().questions) {
      setGameState({
        ...gameState,
        questions: room.data().questions,
      });
    }
  }, [room]);

  /**
   * Submit answers on database
   */
  const submitAnswers = async (data) => {
    try {
      const response = await addDoc(
        collection(db, "rooms", room.id, "responses"),
        { ...data, timestamp: Date.now() }
      );

      return response;
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Add to the user's solved array
   */
  const addToSolved = async (userId, solvedId) => {
    try {
      const userRef = doc(db, "rooms", room.id, "users", userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const user = userDoc.data();

        const solved = user.solved;

        await updateDoc(userRef, {
          solved: [...solved, solvedId],
        });

        return true;
      } else {
        console.error("No such user!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Add start time to room
   */
  const setStartTime = async (startOffset = 300000, endOffset = 1020000) => {
    try {
      const roomRef = doc(db, "rooms", room.id);
      const roomDoc = await getDoc(roomRef);

      if (roomDoc.exists()) {
        const room = roomDoc.data();

        await setDoc(roomRef, {
          ...room,
          startTime: Date.now() + startOffset,
          endTime: Date.now() + endOffset,
        });

        return true;
      } else {
        console.error("No such room!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   *
   */
  const clearStartTime = async () => {
    try {
      const roomRef = doc(db, "rooms", room.id);
      const roomDoc = await getDoc(roomRef);

      if (roomDoc.exists()) {
        const room = roomDoc.data();

        await setDoc(roomRef, {
          ...room,
          startTime: null,
          endTime: null,
        });

        return true;
      } else {
        console.error("No such room!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Get all responses from database
   */
  const getResponses = async () => {
    try {
      const response = await getDocs(
        collection(db, "rooms", room.id, "responses")
      );

      return response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   *
   */
  return (
    <GameContext.Provider
      value={{
        gameState,
        submitAnswers,
        getResponses,
        addToSolved,
        setStartTime,
        clearStartTime,
        time,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
