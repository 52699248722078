import React, { createContext, useState, useContext, useEffect } from "react";

/**
 * Import Packages
 */
import { collection, doc, setDoc } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

/**
 * Import Hooks
 */
import { useFirestore } from "./FirestoreProvider";
import { useRoom } from "./RoomProvider";

/**
 * Import Reducers
 */
import Loader from "../components/Loader/Loader";

/**
 *
 */
import { randomAnimal } from "../data/animals";

/**
 * Create Context for Provider
 */
export const UsersContext = createContext();

/**
 * Hook for Rooms Context
 *
 * @returns Context
 */
export function useUsers() {
  return useContext(UsersContext);
}

/**
 *
 * @param {Object} props
 * @returns
 */
export const UsersProvider = ({ children }) => {
  /**
   * Get our database
   */
  const { db } = useFirestore();

  const { room } = useRoom();

  const [users, setUsers] = useState([]);

  // /**
  //  * Get rooms collection from db
  //  */
  // const getUsers = async () => {
  //   const users = await getDocs(collection(db, "rooms", room.id, "users"));

  //   return users.docs.map((doc) => {
  //     return { id: doc.id, ...doc.data() };
  //   });
  // };

  const [usersCollection, loading, error] = useCollection(
    collection(db, "rooms", room.id, "users"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  /**
   *
   */
  const getUserByID = (id) => {
    return users.find((user) => user.id === id);
  };

  /**
   *
   */
  const getUserByCodeword = (codeword) => {
    return users.find((user) => user.codeword === codeword);
  };

  /**
   * Get User's rank by length of solved array
   */
  const getUserRank = (id) => {
    const user = getUserByID(id);

    return users.filter((u) => u.solved.length > user.solved.length).length + 1;
  };

  /**
   * Submit user on database
   */
  const submitUser = async (data) => {
    try {
      do {
        data.codeword = randomAnimal();
      } while (getUserByCodeword(data.codeword) !== undefined);

      // Add user to collection with custom id
      const response = await setDoc(
        doc(db, "rooms", room.id, "users", data.id),
        { ...data }
      );

      return response;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (usersCollection) {
      setUsers(
        usersCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    }
  }, [usersCollection]);

  // if (!users.length) {
  //   return <Loader message="Fetching users…" />;
  // }

  /**
   *
   */
  return (
    <UsersContext.Provider
      value={{
        users,
        getUserByID,
        getUserByCodeword,
        submitUser,
        getUserRank,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
