import React from "react";

import { useNavigate } from "react-router-dom";

import { useRooms } from "../../contexts/RoomsProvider";

import useSound from "use-sound";

import clickDownSfx from "../../sfx/bloop-1.mp3";

export default function CreateRoomButton() {
  const [clickDown] = useSound(clickDownSfx, { volume: 0.75 });

  const navigate = useNavigate();

  const { createRoom, isCreatingRoom } = useRooms();

  return (
    <button
      className="link-btn"
      onMouseDown={clickDown}
      onMouseUp={async () => {
        const newRoom = await createRoom();

        navigate(`/${newRoom.accessCode}/admin`);
      }}
      disabled={isCreatingRoom}
    >
      {isCreatingRoom ? "Creating room…" : "Create new room"}
    </button>
  );
}
