import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopBar from "./components/TopBar/TopBar";

import "./App.css";
import { FlashMessageProvider } from "./contexts/FlashMessageProvider";
import { RoomsProvider } from "./contexts/RoomsProvider";
import { RoomProvider } from "./contexts/RoomProvider";
import { GameProvider } from "./contexts/GameProvider";
import { UsersProvider } from "./contexts/UsersProvider";

import Home from "./components/Home/Home";
import Game from "./components/Game/Game";

function App() {
  return (
    <div className="App">
      <FlashMessageProvider>
        <Router>
          <TopBar />
          <Routes>
            <Route
              path="/"
              element={
                <RoomsProvider>
                  <Home />
                </RoomsProvider>
              }
            />

            <Route
              path="/:roomId/*"
              element={
                <RoomsProvider>
                  <RoomProvider>
                    <GameProvider>
                      <UsersProvider>
                        <Game />
                      </UsersProvider>
                    </GameProvider>
                  </RoomProvider>
                </RoomsProvider>
              }
            />
          </Routes>
        </Router>
      </FlashMessageProvider>
    </div>
  );
}

export default App;
