import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { useFirestore } from "../../contexts/FirestoreProvider";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import { RoomsProvider, useRooms } from "../../contexts/RoomsProvider";

import clickDownSfx from "../../sfx/bloop-1.mp3";
import clickOnSfx from "../../sfx/bloop-2.mp3";
import FlashMessage from "../FlashMessage/FlashMessage";
import CreateRoomButton from "../CreateRoomButton/CreateRoomButton";

import "./Home.css";

export default function Home() {
  const [roomCode, setRoomCode] = useState("");

  const { auth, loginWithGoogle, logoutWithGoogle, isAdmin } = useFirestore();
  const { addFlashMessage } = useFlashMessage();
  const { getRoomByAccessCode } = useRooms();

  const [clickDown] = useSound(clickDownSfx, { volume: 0.75 });
  const [clickOn] = useSound(clickOnSfx, { volume: 0.75 });
  const navigate = useNavigate();

  const submitRoomCode = () => {
    const room = getRoomByAccessCode(roomCode);

    if (room) {
      navigate(`/${roomCode}`);
      return;
    }

    addFlashMessage("Sorry, we can't find a room with that code.");
    return;
  };

  return (
    <div className="page home">
      <div className="page-content home-content">
        <h1 className="page-title home-title title">
          Who
          <br />
          <em>Shares</em>
          <br />
          Wins
        </h1>
        <p className="big">
          Welcome to Who Shares Wins, a game of interesting secrets 🤫 and
          investigation 🕵🏿🕵🏽‍♀️
        </p>
        <p>
          On the following screen, you'll be asked for a few interesting facts
          ✅ about yourself. Then the investigation begins... 🔍 Secrets will be
          revealed 👀 ... but who do the secrets belong to?
        </p>
        <p>
          Players will be rewarded for finding out... all while people may be
          looking for you!
        </p>
        <input
          type="text"
          placeholder="Enter room code"
          value={roomCode}
          onChange={(e) =>
            setRoomCode(
              e.target.value
                .replace(/[^A-Za-z0-9]/, "")
                .toUpperCase()
                .substring(0, 4)
            )
          }
        />
        <button
          className="btn"
          onMouseDown={clickDown}
          onMouseUp={() => {
            clickOn();
            submitRoomCode();
          }}
        >
          Play
        </button>
      </div>

      <FlashMessage />

      <div className="page-footer home-footer">
        &copy; {new Date().getFullYear()} Interactive Workshops &bull;
        <button
          className="link-btn"
          onMouseDown={clickDown}
          onMouseUp={() =>
            !auth.currentUser?.email ? loginWithGoogle() : logoutWithGoogle()
          }
        >
          {!auth.currentUser?.email
            ? "Login with Google"
            : "Logout with Google"}
        </button>
        {isAdmin && (
          <>
            {" "}
            &bull;
            <CreateRoomButton />
          </>
        )}
      </div>
    </div>
  );
}
