import React, { createContext, useContext, useEffect, useState } from "react";

/**
 * Create Context for Provider
 */
export const FlashMessageContext = createContext();

/**
 * Hook for Rooms Context
 *
 * @returns Context
 */
export function useFlashMessage() {
  return useContext(FlashMessageContext);
}

/**
 *
 * @param {Object} props
 * @returns
 */
export const FlashMessageProvider = ({ children }) => {
  const [flashMessages, setFlashMessages] = useState([]);

  const addFlashMessage = (message) => {
    setFlashMessages((flashMessages) => [
      ...flashMessages,
      { message, id: Date.now(), expires: Date.now() + 3000 },
    ]);
  };

  const removeFlashMessage = (id) => {
    setFlashMessages((flashMessages) =>
      flashMessages.filter((flashMessage) => flashMessage.id !== id)
    );
  };

  const removeExpiredFlashMessages = () => {
    setFlashMessages((flashMessages) =>
      flashMessages.filter((flashMessage) => flashMessage.expires > Date.now())
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      removeExpiredFlashMessages();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  /**
   *
   */
  return (
    <FlashMessageContext.Provider
      value={{ addFlashMessage, removeFlashMessage, flashMessages }}
    >
      {children}
    </FlashMessageContext.Provider>
  );
};
