export default function Shuffle(props) {
  const style = {
    fill: "#000",
    ...props.style,
  };

  return (
    <svg
      width={props.size ? props.size : "1em"}
      height={props.size ? props.size : "1em"}
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      className="check"
    >
      <path
        d="m9 47.398c1.8008 20.5 18.898 36.602 39.398 37.398v9.3008c0 2.6016 2.8984 4.1992 5.1016 2.8008l28.199-17.602c2.1016-1.3008 2.1016-4.3008 0-5.6016l-28.199-17.594c-2.1992-1.3984-5.1016 0.19922-5.1016 2.8008v9.1992h-0.10156c-12.398-0.80078-22.398-11.102-22.797-23.5-0.5-14.5 11.602-26.402 26.199-25.402 10.699 0.69922 21.102 10.301 22.602 21 0.39844 2.6992 0.30078 5.3008-0.10156 7.8008-0.19922 1.3008 0.30078 2.6992 1.5 3.3984l9.3008 5.5c1.8008 1.1016 4.1016 0.19922 4.6992-1.8008 1.1992-4.3008 1.8008-8.8984 1.5-13.602-1.0977-20.297-17.199-36.996-37.398-38.797-25.699-2.3008-47.102 19.102-44.801 44.699z"
        style={style}
      />
    </svg>
  );
}
