import { useEffect, useState } from "react";
import useSound from "use-sound";

import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import { useRoom } from "../../contexts/RoomProvider";
import { useGame } from "../../contexts/GameProvider";
import { useUsers } from "../../contexts/UsersProvider";
import { useUser } from "../../contexts/UserProvider";

import clickDownSfx from "../../sfx/bloop-1.mp3";
import clickOnSfx from "../../sfx/bloop-2.mp3";
import correctSfx from "../../sfx/correct.mp3";
import incorrectSfx from "../../sfx/reject.mp3";

import Loader from "../Loader/Loader";
import WaitingRoom from "../WaitingRoom/WaitingRoom";
import Leaderboard from "../Leaderboard/Leaderboard";
import FlashMessage from "../FlashMessage/FlashMessage";
import Scoreboard from "../Scoreboard/Scoreboard";

import "./Play.css";
import Shuffle from "../Icon/Shuffle/Shuffle";
import Check from "../Icon/Check/Check";

export default function Play() {
  const { room } = useRoom();
  const { getResponses, addToSolved, time } = useGame();
  const { getUserByID, getUserRank } = useUsers();
  const { user } = useUser();
  const { addFlashMessage } = useFlashMessage();

  const [clickDown] = useSound(clickDownSfx, { volume: 0.75 });
  const [clickOn] = useSound(clickOnSfx, { volume: 0.75 });
  const [correct] = useSound(correctSfx, { volume: 0.75 });
  const [incorrect] = useSound(incorrectSfx, { volume: 0.75 });

  const [responses, setResponses] = useState([]);
  const [progress, setProgress] = useState(0);
  const [answerField, setAnswerField] = useState("");
  const [toastExpanded, setToastExpanded] = useState(true);

  // Test if answer matches the user's code word
  const testAnswer = async () => {
    const questionBelongsToUser = await getUserByID(responses[0].user);

    if (answerField === questionBelongsToUser.codeword) {
      setProgress(progress + 1);

      addToSolved(user.id, responses[0].id);

      // Remove response from responses
      setResponses(responses.slice(1));

      setAnswerField("");

      addFlashMessage("Correct!");

      correct();

      return true;
    }

    addFlashMessage("Sorry, that's not correct.");

    incorrect();

    return false;
  };

  // Log roomState when component mounts
  useEffect(() => {
    if (!user.data()) {
      return;
    }

    (async () => {
      const responseData = await getResponses();

      // Randomize the order of the responses
      const randomizedResponses = responseData.sort(() => Math.random() - 0.5);

      // Filter out the user's own response and any solved responses
      const filteredResponses = randomizedResponses
        .filter((r) => r.user !== user.id)
        .filter((r) => !user.data().solved.includes(r.id));

      setResponses(filteredResponses);
    })();
  }, []);

  if (!user.data()) {
    return <Leaderboard />;
  }

  // If no responses, load the loader
  if (!responses.length) {
    return <Loader message="Waiting for responses…" />;
  }

  // If the phase is not 1, redirect to the waiting room
  if (room.data().startTime > time) {
    return <WaitingRoom />;
  }

  return (
    <div className="page play">
      <div className="page-content play-content">
        <h1 className="page-title play-title">Who said it?</h1>

        <div className="play-question">
          {responses.length ? (
            <>
              <p className="play-question-text">{responses[0].question}</p>

              <div className="play-answer">
                <h2
                  style={{
                    fontSize: `${Math.max(
                      Math.min(25 / responses[0].answer.length, 10),
                      1.25
                    )}em`,
                  }}
                >
                  {responses[0].answer}
                </h2>
              </div>

              <button
                className="btn shuffle-button"
                onMouseDown={clickDown}
                onMouseUp={() => {
                  clickOn();

                  setResponses((responses) => {
                    const newResponses = [...responses];

                    newResponses.push(newResponses.shift());

                    return newResponses;
                  });
                }}
              >
                <Shuffle size="2em" style={{ fill: "#fff" }} />
              </button>
            </>
          ) : (
            <p>No more questions!</p>
          )}
        </div>

        <div className="play-answers">
          <p>
            Ask around the room until you find who said it and get their code
            word
          </p>

          <div className="play-answer-form">
            <input
              type="text"
              value={answerField}
              onChange={(e) => {
                setAnswerField(e.target.value.toLowerCase());
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  testAnswer();
                }
              }}
              placeholder="Enter their code word"
            />
            <button className="btn submit-button" onMouseUp={testAnswer}>
              <Check size="1.5em" style={{ fill: "#fff" }} />
            </button>
          </div>
        </div>

        <Scoreboard
          score={user.data().solved.length}
          rank={getUserRank(user.id)}
          endTime={room.data().endTime}
        />
      </div>

      <div
        className="play-toast"
        data-expanded={toastExpanded}
        onMouseDown={() => setToastExpanded(!toastExpanded)}
      >
        <div className="play-user-details">
          <h2 className="play-user-title">Your code word:</h2>
          <h1 className="play-user-codeword">{user.data().codeword}</h1>
          <p className="play-instruction">
            Once you confirm with another player that the answer on their screen
            is yours, give them this code word
          </p>
          <p className="play-instruction small">
            Tap this bar to hide it, and tap it again to show it
          </p>
        </div>
      </div>

      <FlashMessage />

      <div className="page-footer play-footer">
        &copy; {new Date().getFullYear()} Interactive Workshops
      </div>
    </div>
  );
}
