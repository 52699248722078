import { Link } from "react-router-dom";
import "./Logo.css";

export default function Logo() {
  return (
    <div className="site-logo title">
      <Link to="/">Who Shares Wins</Link>
    </div>
  );
}
