import { useUsers } from "../../contexts/UsersProvider";

import "./Leaderboard.css";

export default function Leaderboard() {
  const { users } = useUsers();

  return (
    <>
      {users && users.length > 0 ? (
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {users
              .sort((a, b) => b.solved.length - a.solved.length)
              .map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.solved && user.solved.length}</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p className="leaderboard-empty">No users yet!</p>
      )}
    </>
  );
}
