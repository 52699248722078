import React, { createContext, useContext } from "react";

/**
 * Import Packages
 */
import { doc, getDocs, collection, deleteDoc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

/**
 * Import Hooks
 */
import { useFirestore } from "./FirestoreProvider";
import { Navigate, useParams } from "react-router-dom";
import { useRooms } from "./RoomsProvider";

/**
 * Import Reducers
 */
import Loader from "../components/Loader/Loader";

/**
 * Create Context for Provider
 */
export const RoomContext = createContext();

/**
 * Hook for Rooms Context
 *
 * @returns Context
 */
export function useRoom() {
  return useContext(RoomContext);
}

/**
 *
 * @param {Object} props
 * @returns
 */
export const RoomProvider = ({ children }) => {
  /**
   * Get our database
   */
  const { db } = useFirestore();

  const { roomId } = useParams();

  const { getRoomByAccessCode } = useRooms();

  const roomObj = getRoomByAccessCode(roomId);

  let docRef = "";

  try {
    docRef = doc(db, "rooms", roomObj.id);
  } catch (error) {
    console.log(error);
  }

  const [room, loading, error] = useDocument(docRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  /**
   * Remove all responses from room
   */
  const clearResponses = async () => {
    const responses = await getDocs(
      collection(db, "rooms", roomObj.id, "responses")
    );

    responses.docs.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
  };

  /**
   * Remove all users from room
   */
  const clearUsers = async () => {
    const users = await getDocs(collection(db, "rooms", roomObj.id, "users"));

    users.docs.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
  };

  if (!roomObj?.id) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <Loader message="Fetching room…" />;
  }

  /**
   *
   */
  return (
    <RoomContext.Provider
      value={{
        room,
        clearResponses,
        clearUsers,
      }}
    >
      {children}
    </RoomContext.Provider>
  );
};
