import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";

import { useRoom } from "../../contexts/RoomProvider";
import { useGame } from "../../contexts/GameProvider";
import { useUsers } from "../../contexts/UsersProvider";
import { useFirestore } from "../../contexts/FirestoreProvider";

import clickDownSfx from "../../sfx/bloop-1.mp3";
import clickOnSfx from "../../sfx/bloop-2.mp3";

import Loader from "../Loader/Loader";
import Countdown from "react-countdown-simple";

import "./Join.css";
import WaitingRoom from "../WaitingRoom/WaitingRoom";

export default function Join() {
  const { room } = useRoom();
  const { gameState, submitAnswers } = useGame();
  const { submitUser, getUserByID } = useUsers();
  const { auth } = useFirestore();
  const navigate = useNavigate();
  const [clickDown] = useSound(clickDownSfx, { volume: 0.75 });
  const [clickOn] = useSound(clickOnSfx, { volume: 0.75 });

  const [submitting, setSubmitting] = useState(false);
  const [questions, setQuestions] = useState(gameState.questions);

  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    // Randomise the questions
    const randomisedQuestions = questions.sort(() => Math.random() - 0.5);

    setQuestions(randomisedQuestions);
  }, []);

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div>
      Game starts in {minutes}:{String(seconds).padStart(2, "0")}
    </div>
  );

  const handleNameSubmission = (name) => {
    setSubmitting(true);

    setFieldValue("");

    submitUser({
      name: name,
      id: auth.currentUser.uid,
      solved: [],
    }).then(() => {
      setSubmitting(false);
    });
  };

  const handleQuestionSubmission = (payload) => {
    setSubmitting(true);

    setFieldValue("");

    submitAnswers({
      question: payload.question,
      answer: payload.answer,
      user: auth.currentUser.uid,
    }).then(() => {
      // Remove question from the array
      setQuestions(questions.slice(1));

      setSubmitting(false);
    });
  };

  const fieldLength = process.env.REACT_APP_DEFAULT_FIELD_LENGTH;

  if (questions.length === 0) {
    return <Loader message="Hang tight, you’ve answered all the questions!" />;
  }

  if (submitting) {
    return <Loader message="Submitting response…" />;
  }

  return (
    <div className="page join">
      <div className="page-content join-content">
        <h1 className="page-title join-title">Join</h1>

        <Countdown targetDate={room.data().startTime} renderer={renderer} />

        <div className="join-form">
          {!getUserByID(auth.currentUser.uid) ? (
            <>
              <div className="join-form-field">
                <label htmlFor="name">What's your name?</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter your name"
                  value={fieldValue}
                  onChange={(e) => {
                    setFieldValue(e.target.value.substring(0, fieldLength));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleNameSubmission(fieldValue);
                    }
                  }}
                  autoFocus
                />
                <p className="small">Max {fieldLength} characters</p>
              </div>
              <button
                className="btn"
                onClick={() => handleNameSubmission(fieldValue)}
              >
                Submit
              </button>
            </>
          ) : (
            <>
              <div className="join-form-field">
                <label htmlFor="question">{questions[0]}</label>
                <input
                  type="text"
                  id="question"
                  placeholder="Enter your answer"
                  value={fieldValue}
                  onChange={(e) => {
                    setFieldValue(e.target.value.substring(0, fieldLength));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleQuestionSubmission({
                        question: questions[0],
                        answer: fieldValue,
                      });
                    }
                  }}
                  autoFocus
                />
                <p className="small">Max {fieldLength} characters</p>
              </div>
              <button
                className="btn"
                onClick={() =>
                  handleQuestionSubmission({
                    question: questions[0],
                    answer: fieldValue,
                  })
                }
              >
                Submit
              </button>
            </>
          )}
        </div>
      </div>
      <div className="page-footer join-footer">
        &copy; {new Date().getFullYear()} Interactive Workshops
      </div>
    </div>
  );
}
