import "./TopBar.css";
import Logo from "../Logo/Logo";

export default function TopBar() {
  return (
    <div className="top-bar">
      <Logo />
    </div>
  );
}
