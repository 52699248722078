import { useEffect, useState } from "react";

import { Routes, Route } from "react-router-dom";

import { UserProvider } from "../../contexts/UserProvider";

import { useRoom } from "../../contexts/RoomProvider";

import Join from "../Join/Join";
import Play from "../Play/Play";
import WaitingRoom from "../WaitingRoom/WaitingRoom";
import Admin from "../Admin/Admin";
import Loader from "../Loader/Loader";

export default function Game() {
  const { room } = useRoom();

  const [phase, setPhase] = useState("loading");

  useEffect(() => {
    if (!room) {
      return;
    }

    const checkPhase = setInterval(() => {
      if (!room.data().startTime || !room.data().endTime) {
        setPhase("waiting");
        return;
      }

      if (room.data().endTime < Date.now()) {
        setPhase("finish");
        return;
      }

      if (room.data().startTime < Date.now()) {
        setPhase("play");
        return;
      }

      setPhase("join");
      return;
    }, 1000);

    return () => clearInterval(checkPhase);
  }, [room]);

  return (
    <Routes>
      <Route path="/admin" element={<Admin phase={phase} />} />
      <Route
        path="/*"
        element={
          <>
            {phase === "loading" && <Loader message="Determining phase…" />}
            {phase === "waiting" && <WaitingRoom />}
            {phase === "join" && <Join />}
            {phase === "play" && (
              <UserProvider>
                <Play />
              </UserProvider>
            )}
            {phase === "finish" && <WaitingRoom />}
          </>
        }
      />
    </Routes>
  );
}
