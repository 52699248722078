import { useFlashMessage } from "../../contexts/FlashMessageProvider";

import "./FlashMessage.css";

export default function FlashMessage() {
  const { flashMessages, removeFlashMessage } = useFlashMessage();

  return (
    <div className="flash-message-container">
      {flashMessages.map((flashMessage) => (
        <div
          className="flash-message"
          key={flashMessage.id}
          onClick={() => removeFlashMessage(flashMessage.id)}
        >
          {flashMessage.message}
        </div>
      ))}
    </div>
  );
}
